import { Confidence, DocType, Matrix } from '../@types/common'
import { RecognizeStatsItem } from '../components/RecognizeStats/RecognizeStats'
import {
  RecognizedData,
  ClassifyResponse,
  DocumentInfo,
  RecognizedFields,
  RecognizedField,
} from '../@types/api'
import { v4 as uuidv4 } from 'uuid'

export class Mapper {
  static mapClassifyResponseToDocumentInfo = (
    response: ClassifyResponse,
  ): DocumentInfo[] => {
    return response.items.map((item) => ({
      id: uuidv4(),
      docType: item.document.type,
      crop: item.crop,
    }))
  }

  static mapRecognizedDataToItems(
    recognizedData: RecognizedData,
  ): RecognizeStatsItem[] {
    return Object.keys(recognizedData).map((key: string) => {
      return {
        name: key,
        value: recognizedData[key].text,
        bbox: recognizedData[key].bbox || [],
        // confidenceNumber: recognizedData[key].confidence,
        // confidenceLevel: Mapper.mapConfidence(recognizedData[key].confidence),
      }
    })
  }

  static mapConfidenceToReadable(confidence: Confidence): string {
    return {
      [Confidence.Low]: 'Low',
      [Confidence.Medium]: 'Medium',
      [Confidence.High]: 'High',
    }[confidence]
  }

  static mapConfidence(confidence: number) {
    if (confidence < 0.3) {
      return Confidence.Low
    }

    if (confidence >= 0.3 && confidence <= 0.7) {
      return Confidence.Medium
    }

    return Confidence.High
  }

  static mapDocType = (docType: DocType): string => {
    return {
      bank_card: 'Банковская карта',
      driver_license_1999_paper_back:
        'Водительское Удостоверение: бумажный образец 1999 года (задняя сторона)',
      driver_license_1999_paper_front:
        'Водительское Удостоверение: бумажный образец 1999 года (передняя сторона)',
      driver_license_1999_plastic_back:
        'Водительское Удостоверение: пластиковый образец 1999 года (задняя сторона)',
      driver_license_1999_plastic_front:
        'Водительское Удостоверение: пластиковый образец 1999 года (передняя сторона)',
      driver_license_2011_back:
        'Водительское Удостоверение: образец 2011 года (задняя сторона)',
      driver_license_2011_front:
        'Водительское Удостоверение: образец 2011/2014 года (передняя сторона)',
      driver_license_2014_back:
        'Водительское Удостоверение: образец 2014 года (задняя сторона)',
      driver_license_japan: 'Водительское Удостоверение: японский образец',
      global_passport:
        'Заграничный паспорт РФ: образец 2007 года, главный разворот',
      inn_organisation: 'ИНН юрлица',
      inn_person: 'ИНН физлица',
      insurance_plastic: 'СНИЛС старого образца',
      kgz_passport_main: 'Паспорт гражданина Киргизии: главный разворот',
      kgz_passport_plastic_blue: 'Паспорт гражданина Киргизии: голубой образец',
      kgz_passport_plastic_red: 'Паспорт гражданина Киргизии: красный образец',
      migration_card: 'Миграционная карта РФ',
      military_id: 'Военный билет РФ',
      mts_acts: 'Акт МТС',
      mts_rfa: 'РФА МТС',
      ndfl2: 'Справка по форме 2НДФЛ',
      not_document: 'Не является документом',
      ogrn: 'ОГРН',
      ogrnip: 'ОГРНИП',
      other: 'Форма документа не определена',
      passport_blank_page: 'Паспорт РФ: пустая страница',
      passport_children: 'Паспорт РФ: страница Дети',
      passport_last_rf: 'Паспорт РФ: задний разворот',
      passport_main: 'Паспорт РФ: главный разворот, печатный образец',
      passport_main_handwritten:
        'Паспорт РФ: главный разворот, рукописный образец',
      passport_marriage: 'Паспорт РФ: страница «Семейное положение»',
      passport_military: 'Паспорт РФ: страница «Воинская обязанность»',
      passport_previous_docs:
        'Паспорт РФ: страница «Сведения о ранее выданных паспортах»',
      passport_registration: 'Паспорт РФ: страница «Место жительства»',
      passport_zero_page: 'Паспорт РФ: передний разворот',
      pts_back: 'Паспорт ТС: образец 2006 года, задняя сторона',
      pts_front: 'Паспорт ТС: образец 2006 года, передняя сторона',
      registration_certificate: 'Сертификат о регистрации права',
      rus_work_patent: 'Патент на работу: образец ФМС РФ',
      snils_back: 'СНИЛС: устаревший образец, задняя сторона',
      snils_front: 'СНИЛС: устаревший образец, передняя сторона',
      tjk_passport_main: 'Паспорт гражданина Таджикистана: главный разворот',
      uzb_passport_main: 'Паспорт гражданина Узбекистана: главный разворот',
      vehicle_registration_certificate_back:
        'Свидетельство о регистрации ТС: задняя сторона',
      vehicle_registration_certificate_front:
        'Свидетельство о регистрации ТС: передняя сторона',
    }[docType]
  }

  static mapRecognizeFieldsResponse(
    fields: RecognizedField[],
  ): RecognizedFields {
    console.log(fields)
    const reduced = fields.reduce((accum, next, index) => {
      return {
        ...accum,
        [`${next.name} ${index}`]: {
          name: next.name,
          text: next.value,
          bbox: next.coords.map(c => [c[0], c[2]]),
        },
      }
    }, {})
    console.log(reduced)
    return reduced
  }

  static mapDocumentFieldName(docType: string, fieldName: string) {
    return fieldMap[docType]
      ? fieldMap[docType][fieldName] || fieldName
      : fieldName
  }
}

export const fieldMap: Matrix<string> = {
  bank_card: {
    cardholder_name: 'Cardholder name',
    month: 'Expiration Date: month',
    number: 'Card number',
    year: 'Expiration Date: year',
  },
  driver_license_1999_paper_back: {
    category_a: 'Категория A',
    category_b: 'Категория B',
    category_c: 'Категория C',
    category_d: 'Категория D',
    category_e: 'Категория E',
    series_top: 'Серия документа: верхняя часть',
    number_top: 'Номер документа: верхняя часть',
    series_bottom: 'Серия документа: нижняя часть (при наличии)',
    number_bottom: 'Номер документа нижняя часть (при наличии)',
    special_marks: 'Особые отметки',
  },
  driver_license_1999_paper_front: {
    date_of_birth: 'Дата рождения',
    date_of_issue: 'Дата выдачи',
    name: 'Имя',
    surname: 'Фамилия',
    third_name: 'Отчество',
    valid_before: 'Действителен до',
    series_top: 'Серия документа: верхняя часть',
    number_top: 'Номер документа: верхняя часть',
    series_bottom: 'Серия документа: нижняя часть (при наличии)',
    number_bottom: 'Номер документа нижняя часть (при наличии)',
  },
  driver_license_1999_plastic_front: {
    category: 'Категории прав',
    date_end: 'Действительно до',
    date_from: 'Дата выдачи',
    date_of_birth: 'Дата рождения',
    doc_number: 'Номер документа',
    doc_series: 'Серия документа',
    name: 'Имя',
    patronymic: 'Отчество',
    place_of_birth: 'Место рождения',
    residency: 'Место жительства',
    special: 'особые отметки',
    surname: 'Фамилия',
    issuer: 'Подразделение, выдавшее документ',
  },
  driver_license_2011_back: {
    category_a_begin: 'Категория A: начало действия',
    category_a_end: 'Категория A: конец действия',
    category_b_begin: 'Категория B: начало действия',
    category_b_end: 'Категория B: конец действия',
    category_be_begin: 'Категория Be: начало действия',
    category_be_end: 'Категория Be: конец действия',
    category_c_begin: 'Категория C: начало действия',
    category_c_end: 'Категория C: конец действия',
    category_ce_begin: 'Категория Ce: начало действия',
    category_ce_end: 'Категория Ce: конец действия',
    category_d_begin: 'Категория D: начало действия',
    category_d_end: 'Категория D: конец действия',
    category_de_begin: 'Категория De: начало действия',
    category_de_end: 'Категория De: конец действия',
    category_tb_begin: 'Категория Tb: начало действия',
    category_tb_end: 'Категория Tb: конец действия',
    category_tm_begin: 'Категория Tm: начало действия',
    category_tm_end: 'Категория Tm: конец действия',
    series_number: 'Серия и номер документа',
    special_marks: 'Особые отметки',
  },
  driver_license_2011_front: {
    category: 'Категории прав',
    date_end: 'Действительно до',
    date_from: 'Дата выдачи',
    date_of_birth: 'Дата рождения',
    issuer: 'Подразделение, выдавшее документ',
    number: 'Номер документа',
    place_of_birth: 'Место рождения',
    place_of_issue: 'Место выдачи',
    surname: 'Фамилия',
    name: 'Имя',
    patronymic: 'Отчество',
  },
  driver_license_2014_back: {
    category_a1_begin: 'Категория A1: начало действия',
    category_a1_end: 'Категория A1: конец действия',
    category_a_begin: 'Категория A: начало действия',
    category_a_end: 'Категория A: конец действия',
    category_b1_begin: 'Категория B1: начало действия',
    category_b1_end: 'Категория B1: конец действия',
    category_b_begin: 'Категория B: начало действия',
    category_b_end: 'Категория B: конец действия',
    category_be_begin: 'Категория Be: начало действия',
    category_be_end: 'Категория Be: конец действия',
    category_c1_begin: 'Категория C1: начало действия',
    category_c1_end: 'Категория C1: конец действия',
    category_c1e_begin: 'Категория C1e: начало действия',
    category_c1e_end: 'Категория C1e: конец действия',
    category_c_begin: 'Категория C: начало действия',
    category_c_end: 'Категория C: конец действия',
    category_ce_begin: 'Категория Ce: начало действия',
    category_ce_end: 'Категория Ce: конец действия',
    category_d1_begin: 'Категория D1: начало действия',
    category_d1_end: 'Категория D1: конец действия',
    category_d1e_begin: 'Категория D1e: начало действия',
    category_d1e_end: 'Категория D1e: конец действия',
    category_d_begin: 'Категория D: начало действия',
    category_d_end: 'Категория D: конец действия',
    category_de_begin: 'Категория De: начало действия',
    category_de_end: 'Категория De: конец действия',
    category_m_begin: 'Категория M: начало действия',
    category_m_end: 'Категория M: конец действия',
    category_tb_begin: 'Категория Tb: начало действия',
    category_tb_end: 'Категория Tb: конец действия',
    category_tm_begin: 'Категория Tm: начало действия',
    category_tm_end: 'Категория Tm: конец действия',
    series_number: 'Серия и номер документа',
    special_marks: 'Особые отметки',
  },
  driver_license_japan: {
    address: 'Address',
    birthday: 'Birthday',
    conditions: 'Conditions',
    expiry_date: 'Expiry date',
    issue_date: 'Issue date',
    issuing_authority: 'Issuing authority',
    license_number: 'License number',
    name: 'Name',
    photo: 'Photo',
  },
  inn_person: {
    date: 'Дата выдачи',
    fio: 'ФИО',
    inn: 'ИНН',
    issuer_number: 'Номер налогового органа',
    number: 'Номер документа',
    series: 'Серия документа',
  },
  kgz_passport_main: {
    date_of_birth: 'Дата рождения',
    date_of_expiry: 'Дата выдачи',
    date_of_issue: 'Дата окончания срока действия',
    issuer: 'Орган, выдавший документ',
    name: 'Имя',
    nation: 'Гражданство',
    passport_num: 'Паспорт №',
    personal_number: 'Персональный номер',
    place_of_birth: 'Место рождения',
    sex: 'Пол',
    surname: 'Фамилия',
  },
  kgz_passport_plastic_blue: {
    date_of_brith: 'Дата рождения',
    date_of_expiry: 'Срок действия',
    document_number: '№ документа',
    name: 'Имя',
    nation: 'Гражданство',
    patron: 'Отчество',
    sex: 'Пол',
    surname: 'Фамилия',
  },
  kgz_passport_plastic_red: {
    date_of_birth: 'Дата рождения',
    document_number: 'Документ №',
    name: 'Имя',
    nation: 'Национальность',
    patron: 'Отчество',
    personal_number: 'Персональный номер',
    place_of_birth: 'Место рождения',
    sex: 'Пол',
    surname: 'Фамилия',
  },
  migration_card: {
    from: 'Срок пребывания от',
    number: 'Номер',
    series: 'Серия',
  },
  mts_rfa: {
    number: 'Номер карты',
    phone_number: 'Номер телефона',
    sim_number: 'Номер сим-карты',
  },
  ndfl2: {
    agent: 'Налоговый агент',
    agent_inn: 'ИНН',
    bottom_0_0_0: '5. Общие суммы дохода и налога',
    date_of_birth: 'Дата рождения',
    doc_date: 'Дата формирования справки',
    middle_0_0_0: '4. Стандартные, социальные и имущественные налоговые вычеты',
    name: 'Имя',
    other_name: 'Отчество',
    ru_inn: 'ИНН в Российской Федерации',
    surname: 'Фамилия',
    top_left_table_0_00_00: '3. Доходы - левая часть таблицы',
    top_right_table_0_00_00: '3. Доходы - правая часть таблицы',
  },
  ogrn: {
    company_name: 'Фирменное наименование юрлица (при наличии)',
    day: 'Дата создания юрлица: день',
    full_name: 'Полное наименование юрлица',
    issuer: 'Наименование регистрирующего органа',
    month: 'Дата создания юрлица: месяц',
    ogrn_number: 'ОГРН',
    series_and_number: 'Серия и номер документа',
    short_name: 'Сокращенное наименование юрлица (при наличии)',
    year: 'Дата создания юрлица: год',
  },
  passport_main: {
    date_of_birth: 'Дата рождения',
    date_of_issue: 'Дата выдачи',
    first_name: 'Имя',
    issuing_authority: 'Паспорт выдан',
    other_names: 'Отчество',
    place_of_birth: 'Место рождения',
    series_and_number: 'Серия и номер документа',
    sex: 'Пол',
    subdivision_code: 'Код подразделения',
    surname: 'Фамилия',
  },
  passport_registration: {
    stamp_registration: 'Место жительства',
  },
  rus_work_patent: {
    citizenship: 'Гражданство',
    date_of_birth: 'Дата рождения',
    document_number: 'Документ, удост. личность',
    itn: 'ИНН',
    name: 'Имя',
    number: 'Номер',
    patter: 'Отчество',
    series: 'Серия',
    surname: 'Фамилия',
  },
  snils_front: {
    day_of_birth: 'Дата рождения: день',
    day_of_issue: 'Дата регистрации: день',
    month_of_birth: 'Дата рождения: месяц',
    month_of_issue: 'Дата регистрации: месяц',
    name: 'Имя',
    number: 'Номер',
    place_of_birth: 'Место рождения',
    sex: 'Пол',
    surname: 'Фамилия',
    third_name: 'Отчество',
    year_of_birth: 'Дата рождения: год',
    year_of_issue: 'Дата регистрации: год',
  },
  tjk_passport_main: {
    date_of_birth: 'Дата рождения',
    date_of_expiry: 'Дата окончания срока действия',
    date_of_issue: 'Дата выдачи',
    name: 'Имя',
    nation: 'Национальность',
    number: 'Номер',
    sex: 'Пол',
    surname: 'Фамилия',
  },
  uzb_passport_main: {
    authority: 'Орган, выдавший документ',
    date_of_birth: 'Дата рождения',
    date_of_expiry: 'Дата окончания срока действия',
    date_of_issue: 'Дата выдачи',
    gender: 'Пол',
    name: 'Имя',
    nation: 'Национальность',
    number: 'Номер',
    place_of_birth: 'Место рождения',
    surname: 'Фамилия',
  },
  vehicle_registration_certificate_back: {
    apartment_number: 'Квартира',
    building_number: 'Корпус',
    city: 'Нас. пункт',
    house_number: 'Дом',
    name: 'Собственник: имя (на английском)',
    name_rus: 'Собственник: имя (на русском)',
    number_bottom: 'Номер СТС (нижняя часть)',
    number_top: 'Номер СТС (верхняя часть)',
    patronymic_rus: 'Собственник: отчество (на русском)',
    police_unit_code: 'Орган, выдавший документ',
    region: 'Район',
    series_top: 'Серия СТС (верхняя часть)',
    special_marks: 'Особые отметки',
    street: 'Улица',
    surname: 'Собственник: фамилия (на английском)',
    surname_rus: 'Собственник: фамилия (на русском)',
    series_bottom: 'Серия СТС (нижняя часть)',
    date: 'Дата выдачи',
    province: 'Республика, край, область',
  },
  vehicle_registration_certificate_front: {
    brand_model_eng: 'Марка, модель (на английском)',
    brand_model_rus: 'Марка, модель (на русском)',
    color: 'Цвет',
    document_number: 'Серия и номер СТС',
    ecologic_class: 'Экологический класс',
    mass: 'Масса без нагрузки',
    max_mass: 'Разрешенная max масса',
    passport_number: 'Паспорт ТС: номер',
    passport_series: 'Паспорт ТС: серия',
    reg_number: 'Регистрационный знак',
    release_year: 'Год выпуска ТС',
    vehicle_body: 'Кузов №',
    vehicle_category: 'Категория ТС',
    vehicle_chassis: 'Шасси №',
    vehicle_type: 'Тип ТС',
    vin: 'Номер VIN',
    engine_kw: 'Мощность двигателя, кВт',
    engine_hp: 'Мощность двигателя, л. с.',
  },
}
